.projectListContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80vw;
  margin: auto;

  .imageContainer {
    flex: 0 0 33.3%;
    min-width: 350px;
    min-height: 350px;
    max-height: 350px;
    overflow: hidden;

    .image {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transition: transform 0.1s ease-in-out;

      &:hover {
        transform: scale(1.1);
        transform-origin: 50% 50%;
      }
    }
  }
}
