@import '../colors';
@import '../sizing';

.panel {
  display: flex;
  padding: 50px 20px;
  flex-direction: column;
  align-items: center;
  margin: 0;

  .panelTitle {
    letter-spacing: 4px;
    margin: 0;
    font-size: 40px;
  }
  .titleLine {
    border-bottom: 2px solid cornflowerblue;
    margin-top: 10px;
    height: 3px;
    width: 100px;
  }

  .panelContent {
    margin-top: 50px;
    width: 100%;

    @media (max-width: $small-device-width) {
      padding: 10px;
    }
  }
}

.panelDark {
  background-color: $dark-bg;
}

.panelLight {
  background-color: $light-bg;
}
