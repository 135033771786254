.root {
  height: 100%;
  display: flex;
  flex-direction: column;

  .pageContent {
    flex: 1 0 auto;
  }

  .stickyFooter {
    flex-shrink: 0;
    overflow: hidden;
  }
}
