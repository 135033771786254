@import "../sizing";

.serviceList {
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: start;
  width: 80vw;
  max-width: 1500px;

  @media (max-width: $small-device-width) {
    grid-template-columns: 1fr;
    padding: 0;
  }
  @media (min-width: $xl-device-width) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
