@import "../colors";
@import "../sizing";

.headerContainer {
  min-height: 100px;
  display: flex;
  align-items: center;
  align-content: stretch;
  padding: 0 50px;
  box-shadow: 0 0 5px 0 #222222;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (max-width: $small-device-width) {
    flex-direction: column;
    padding: 20px;
  }

  .menuContainer {

    display: flex;
    margin: 0 20px 0 auto;

    @media (max-width: $small-device-width) {
      flex-direction: column;
      align-content: center;
      margin: 20px;
    }

    .menuItem {
      padding: 10px 20px;
      font-size: 120%;
      letter-spacing: 2px;
      text-align: center;

      a {
        color: $dark-fg;
        text-decoration: none;
        transition: 0.3s;
        white-space: nowrap;

        &:hover {
          color: $dark-fg-hover;
        }
      }
    }
  }
}
