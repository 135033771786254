.partnersContainer {
  display: flex;
  justify-content: center;
  gap: 50px;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;

  .imageContainer {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 100px;
    width: 200px;
    background-color: white;
    padding: 5px 20px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  }
}
