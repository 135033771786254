@import '../colors';
@import '../sizing';


.headlineContainer {
  background-color: $light-bg;
  display: flex;
  justify-content: center;

  @media (max-width: $small-device-width) {
    margin: 0;
  }

  .imageContainer {
    position: relative;
    margin-top: 50px;
    height: 500px;
    width: 900px;
    background-position: center;
    background-size: cover;

    @media (max-width: $small-device-width) {
      margin-top: 0;
      width: 100%;
      height: 300px;
      border-radius: 0;
    }
  }
}
