@import '../sizing';

.googleMapContainer {
  position: relative;
  box-sizing: border-box;
  margin: auto;
  margin-bottom: 100px;
  width: 60vw;
  height: 400px;
  overflow: hidden;

  @media (max-width: $small-device-width) {
    width: 100vw;
  }

  .googleMap {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    border: 0;
  }
}
