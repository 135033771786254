@import "../sizing";
@import "../colors";

.contactPanelContainer {
  display: flex;
  gap: 50px;
  align-items: start;

  @media (max-width: $small-device-width) {
    flex-direction: column;
  }

  .contactInfo {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: auto;
    margin-top: 16px;
    justify-content: center;
    @media (max-width: $small-device-width) {
      text-align: center;
      margin: auto;
    }

    .contactInfoItem {
      text-decoration: none;
      color: $light-fg;
      display: flex;
      flex-direction: column;
      align-items: end;
      gap: 5px;

      @media (max-width: $small-device-width) {
        align-items: center;
      }

      .contactInfoLabel {
        display: flex;
        gap: 5px;

        @media (max-width: $small-device-width) {
          align-items: center;
          flex-direction: column-reverse;
        }
      }

      .contactInfoValue {
        margin-top: 0;
      }
    }
  }

  .contactForm {
    margin-right: auto;
    min-width: 350px;

    @media (max-width: $small-device-width) {
      margin: auto;
      width: 100%;
    }

    .buttonContainer {
      display: flex;
      justify-content: end;
    }

    .recaptchaMessage {
      max-width: 150px;
      font-size: 9px;
      color: #aaa;
      margin-right: 10px;
      text-align: right;

      a {
        color: #aaa;
      }
    }

    .statusContainer {
      position: relative;

      .statusMessage {
        text-align: center;
        //transition: opacity 0.1s ease-in-out;
        color: white;
        font-weight: bold;
        padding: 10px 20px;
        width: 100%;
        box-sizing: border-box;

        &.error {
          background-color: #d35252;
        }

        &.success {
          background-color: #58c558;
        }
      }
    }
  }
}
