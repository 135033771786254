.serviceDetailsContainer {
  animation: fadeInAnimation ease 1s;

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px;

  .title {
    letter-spacing: 2px;
  }

  .thumbnail {
    height: 300px;
    width: 300px;
    object-fit: cover;
    border-radius: 50%;
  }

  .description {
    max-width: 520px;
    letter-spacing: 0.4px;
  }
}
