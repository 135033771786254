@import "../colors";

.service {
  user-select: none;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  cursor: pointer;
  transition: all 0.1s ease-in;
  padding: 10px;
  margin: 10px;

  &:hover {

    transform: scale(1.02);

    .title {
      transform: scale(1.05);
    }

    .titleSeparator {
      width: 100px;
    }
  }

  .imageContainer {
    .image {
      width: 100%;
      max-width: 260px;
      height: 260px;
      object-fit: cover;
    }
  }

  .title {
    font-size: 25px;
    transition: transform 0.1s ease-in;
    box-sizing: border-box;
    letter-spacing: 1px;
    margin: 10px 0;
    text-align: center;
    font-weight: 300;
    color: cornflowerblue;
  }

  .titleSeparator {
    transition: width 0.1s ease-in;
    margin: 10px 0;
    width: 50px;
    border-bottom: 1px solid #aaa;
  }

  .description {
    margin: 10px 0;
    font-weight: 300;
    text-align: center;
    letter-spacing: 1px;
  }

}
