.footer {
  display: flex;
  justify-content: center;
  margin-top: auto;
  border-top: 1px solid #eee;

  .footerText {
    font-weight: 300;
    font-size: 0.8em;
    margin: 20px;
  }
}
